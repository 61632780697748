<template>
  <div class="" style="">
    <Main v-if="$auth.user" />
    <LoginForm v-else :errorMessage="errorMessage" @submit="login" />
  </div>
</template>

<script>
import Main from "./Main";
import LoginForm from "./LoginForm";

export default {
  data() {
    return {
      errorMessage: ""
    };
  },

  methods: {
    login({ email, password }) {
      this.$auth.login(email, password).then(() => {
        if (!this.$auth.user) {
          this.errorMessage = "Echec de l'authentification, merci de réessayer";
        }else{
          this.errorMessage = "";
        }
      });
    }
  },

  created() {
    this.$auth.init();
  },

  components: { Main, LoginForm }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;1,100;1,300&display=swap');


body { 
  font-family: 'Kanit', sans-serif!important;
  background-color: rgb(0, 0, 0)!important;
  /* background-image: url("/picto_arbre_appli.png")!important; */
  min-height: 100vh; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color:#272727;
}

p,h1,h2,h3,h4,h5 {
  font-family: 'Kanit', sans-serif!important;
  color:rgb(0 74 124)!important;
  }
.h1{
  font-family: 'Kanit', sans-serif!important;
  color:rgb(0 74 124)!important;
}
.navbar { 
  padding-top:0px!important;
  background-color: rgb(0 74 124)!important;
  color:#fff;
}
.navbar p h1 h2 h3 h4 h5 {
  color:#fff!important;
  }
#app {
  font-family: 'Kanit', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.error {
  color: tomato!important;
}
.success {
  color: rgb(99, 192, 114)!important;
}
.warning {
  color:rgb(219, 149, 17)!important;
}

.white {
  color:#fff!important;
}
.btn_myicon {
  border-radius: 50%!important;
  border: 2px solid rgb(219, 149, 17)!important;
  color: rgb(219, 149, 17)!important;
  
}
.btn_myicon:active {
    color: #fff!important;
    background-color: rgb(219, 149, 17)!important;
    border-color: rgb(219, 149, 17)!important;
}
.btn_myicon:hover {
    color: #fff!important;
    background-color: rgb(219, 149, 17)!important;
    border-color: rgb(219, 149, 17)!important;
    
}
.btn_myicon_success {
  border-radius: 50%!important;
  color: #fff!important;
  background-color: rgb(0 74 124)!important;
  border-color: rgb(0 74 124)!important;
  width: 135px;
  height:135px;
  /* position:fixed;
  bottom: 20px;
  right:20px;
  z-index:100; */
}
.btn_myicon_success:active {
    background-color: none!important;
  border: 5px solid rgb(0 74 124)!important;
  color: rgb(0 74 124)!important;
}
.btn_myicon_success:hover {
    background-color: none!important;
  border: 5px solid rgb(219, 149, 17)!important;
  color: rgb(219, 149, 17)!important;
}
.btn_icontools_green {
  border-radius: 50%!important;
  border: 2px solid #198754!important;
  color: #198754!important;
}
.btn_icontools_green:active {
    color: #fff!important;
    background-color: #198754!important;
    border-color: #198754!important;
}
.btn_icontools_green:hover {
    color: #fff!important;
    background-color: #198754!important;
    border-color: #198754!important;
}
.msg_overlay {
  position:fixed;
    padding-top: 50%;
  top:0px;
  left:0px;
  height:100vh;
  width:100vw;
  background-color: rgba(0, 0, 0, 0.91);
  z-index: 1300!important;
}
.btn-sofcot {
    color: #fff!important;
    background-color: rgb(0 74 124)!important;
    border-color: rgb(0 74 124)!important;
}
.btn-sofcot:hover {
    color: rgb(0 74 124)!important;
    background-color: #fff!important;
    border-color: rgb(0 74 124)!important;
}
.btn-sofcot:active {
    color: rgb(0 74 124)!important;
    background-color: #fff!important;
    border-color: rgb(0 74 124)!important;
}

.btn-sofcot-inv {
    color: rgb(0 74 124)!important;
    background-color: #fff!important;
    border-color: rgb(0 74 124)!important;
}
.btn-sofcot_inv:hover {
    color: #fff!important;
    background-color: rgb(0 74 124)!important;
    border-color: rgb(0 74 124)!important;
}
.btn-sofcot_inv:active {
    color: #fff!important;
    background-color: rgb(0 74 124)!important;
    border-color: rgb(0 74 124)!important;
}
.green_back{
  background-color: #198754!important;
}
.red_back{
  background-color: brown!important;
}
</style>